(function() {
    'use strict';

    angular.module('ionicUserType', [
        'ionicAlert',
        'ionicData',
        'ionicDataTable',
        'ionicFeature',
        'ionicFilter',
        'ionicForceRefresh',
        'ionicLogin',
        'ionicProject',
        'ionicRequest',
        'ionicSettings',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicUserType').service(
        'UserTypeCreateService',
        UserTypeCreateService
    );

    UserTypeCreateService.$inject = [
        'DefaultVariableService',
        'ProjectService',
        'RequestManagementService',
        'UserTypeListService'
    ];
    
    function UserTypeCreateService(
        DefaultVariableService,
        ProjectService,
        RequestManagementService,
        UserTypeListService
    ) {
        var UserTypeCreateService = this;

        UserTypeCreateService.createUserType = createUserType;
        function createUserType(newUserType) {
            var projectId = DefaultVariableService.getInteger(
                newUserType.project_id,
                0
            );

            if (projectId === 0) {
                return ProjectService.getProject().then(
                    function(project) {
                        if (project) {
                            newUserType.project_id = project.id;

                            return UserTypeCreateService.createUserType(newUserType);
                        }

                        return false;
                    }
                );
            }

            newUserType.display_name = DefaultVariableService.get(
                newUserType.display_name,
                newUserType.name
            );

            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'user_types');
            options = RequestManagementService.setAction(options, 'add');

            options = RequestManagementService.setData(options, newUserType);

            return ListManager.create(options);
        }

        UserTypeCreateService.reset = reset;
        function reset() {

        }

        var ListManager = UserTypeListService.getListManager();
        
        return UserTypeCreateService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicUserType').service('UserTypeEditService', UserTypeEditService);

    UserTypeEditService.$inject = [
        'DefaultVariableService',
        'RequestManagementService',
        'UserTypeListService'
    ];
    
    function UserTypeEditService(
        DefaultVariableService,
        RequestManagementService,
        UserTypeListService
    ) {
        var UserTypeEditService = this;

        UserTypeEditService.deleteUserType = deleteUserType;
        function deleteUserType(userType) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'user_types');
            options = RequestManagementService.setAction(options, 'delete', userType.id);

            return ListManager.remove(options);
        }

        UserTypeEditService.editUserType = editUserType;
        function editUserType(userType) {
            userType.display_name = DefaultVariableService.getString(
                userType.name
            );

            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'user_types');
            options = RequestManagementService.setAction(options, 'edit');

            options = RequestManagementService.setData(options, userType);

            return ListManager.edit(options);
        }

        UserTypeEditService.reset = reset;
        function reset() {

        }

        var ListManager = UserTypeListService.getListManager();

        UserTypeEditService.reset();
        
        return UserTypeEditService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicUserType').service('UserTypeListService', UserTypeListService);

    UserTypeListService.$inject = [
        'DefaultVariableService',
        'ListManagementService',
        'RequestManagementService'
    ];
    
    function UserTypeListService(
        DefaultVariableService,
        ListManagementService,
        RequestManagementService
    ) {
        var UserTypeListService = this;

        UserTypeListService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        UserTypeListService.getUserTypes = getUserTypes;
        function getUserTypes() {
            return UserTypeListService.loadUserTypes();
        }

        UserTypeListService.loadUserTypes = loadUserTypes;
        function loadUserTypes(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'user_types');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setLimit(options, 100);

            return ListManager.getPage(options);
        }

        UserTypeListService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager(
            UserTypeListService.getUserTypes
        );

        UserTypeListService.reset();
        
        return UserTypeListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicUserType').service(
        'UserTypeManagementService',
        UserTypeManagementService
    );

    UserTypeManagementService.$inject = [
        'FeatureEditService',
        'FeatureListService',
        'UserTypeCreateService',
        'UserTypeEditService',
        'UserTypeListService'
    ];

    function UserTypeManagementService(
        FeatureEditService,
        FeatureListService,
        UserTypeCreateService,
        UserTypeEditService,
        UserTypeListService
    ) {
        var UserTypeManagementService = this;

        UserTypeManagementService.createUserType = createUserType;
        function createUserType(newUserType) {
            return UserTypeCreateService.createUserType(newUserType);
        }

        UserTypeManagementService.deleteUserType = deleteUserType;
        function deleteUserType(userType) {
            return UserTypeEditService.deleteUserType(userType);
        }

        UserTypeManagementService.editUserType = editUserType;
        function editUserType(userType) {
            return UserTypeEditService.editUserType(userType);
        }

        UserTypeManagementService.getFeatures = getFeatures;
        function getFeatures(userType) {
            return FeatureListService.getFeatures(userType);
        }

        UserTypeManagementService.getUserTypes = getUserTypes;
        function getUserTypes(options) {
            return UserTypeListService.loadUserTypes(options);
        }

        UserTypeManagementService.saveFeatures = saveFeatures;
        function saveFeatures(userType) {
            return FeatureEditService.saveFeatures(userType);
        }

        return UserTypeManagementService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicUserType').controller(
        'UserTypeCreateController',
        UserTypeCreateController
    );
    
    UserTypeCreateController.$inject = [
        'DefaultVariableService',
        'LoginService',
        '$scope',
        'UserTypeCreateService'
    ];
    
    function UserTypeCreateController(
        DefaultVariableService,
        LoginService,
        $scope,
        UserTypeCreateService
    ) {
        var UserTypeCreateController = this;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                UserTypeCreateController.onCancel = DefaultVariableService.getPromise(
                    onCancel
                );
            }
        );

        $scope.$watch(
            'onCreate',
            function(onCreate) {
                UserTypeCreateController.onCreate = DefaultVariableService.getPromise(
                    onCreate
                );
            }
        );

        UserTypeCreateController.cancel = cancel;
        function cancel() {
            UserTypeCreateController.onCancel();
        }

        UserTypeCreateController.clear = clear;
        function clear() {
            UserTypeCreateController.reset();
            UserTypeCreateService.reset();
        }

        UserTypeCreateController.createUserType = createUserType;
        function createUserType(newUserType) {
            UserTypeCreateController.isCreatingUserType = true;

            newUserType = DefaultVariableService.get(
                newUserType,
                UserTypeCreateController.newUserType
            );

            return UserTypeCreateService.createUserType(newUserType).then(
                function(newUserType) {
                    if (newUserType) {
                        UserTypeCreateController.reset();

                        UserTypeCreateController.onCreate(newUserType);
                    }

                    return newUserType;
                }
            ).finally(
                function() {
                    UserTypeCreateController.isCreatingUserType = false;
                }
            );
        }

        UserTypeCreateController.reset = reset;
        function reset() {
            UserTypeCreateController.isCreatingUserType = false;

            UserTypeCreateController.newUserType = {};
        }

        UserTypeCreateController.init = init;
        function init() {
            UserTypeCreateController.reset();
        }

        LoginService.register(UserTypeCreateController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicUserType').directive(
        'ionicUserTypeCreate',
        ionicUserTypeCreate
    );

    function ionicUserTypeCreate() {
        return {
            controller:   'UserTypeCreateController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {
                onCancel: '=',
                onCreate: '='
            },
            template:'<div><label class="item item-input item-floating-label"><span class="input-label">NAME</span> <input type="text" placeholder="NAME" data-ng-model="ctrl.newUserType.name"></label><div class="row"><button class="button button-full accept-button col-xl-6" data-ng-disabled="ctrl.isCreatingUserType" data-ng-click="ctrl.createUserType()">{{ ctrl.isCreatingUserType ? \'CREATING...\' : \'CREATE\' }}</button> <button class="button button-full decline-button col-xl-6" data-ng-disabled="ctrl.isCreatingUserType" data-ng-click="ctrl.cancel()">CANCEL</button></div></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicUserType').controller(
        'UserTypeEditController',
        UserTypeEditController
    );
    
    UserTypeEditController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'LoginService',
        '$scope',
        'UserTypeEditService'
    ];
    
    function UserTypeEditController(
        AlertService,
        DefaultVariableService,
        LoginService,
        $scope,
        UserTypeEditService
    ) {
        var UserTypeEditController = this;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                UserTypeEditController.onCancel = DefaultVariableService.getPromise(
                    onCancel
                );
            }
        );

        $scope.$watch(
            'onDelete',
            function(onDelete) {
                UserTypeEditController.onDelete = DefaultVariableService.getPromise(
                    onDelete
                );
            }
        );

        $scope.$watch(
            'onEdit',
            function(onEdit) {
                UserTypeEditController.onEdit = DefaultVariableService.getPromise(
                    onEdit
                );
            }
        );

        $scope.$watch(
            'userType',
            function(userType) {
                UserTypeEditController.userType = DefaultVariableService.getObject(
                    userType
                );
            }
        );

        UserTypeEditController.cancel = cancel;
        function cancel() {
            UserTypeEditController.onCancel();
        }

        UserTypeEditController.clear = clear;
        function clear() {
            UserTypeEditController.reset();
            UserTypeEditService.reset();
        }

        UserTypeEditController.deleteUserType = deleteUserType;
        function deleteUserType(userType) {
            userType = DefaultVariableService.get(
                userType,
                UserTypeEditController.userType
            );

            UserTypeEditController.isDeletingUserType = true;

            return UserTypeEditService.deleteUserType(userType).then(
                function(success) {
                    if (success) {
                        AlertService.addMessage('USER TYPE DELETED.');

                        UserTypeEditController.onDelete(success);
                    }

                    return success;
                }
            ).finally(
                function () {
                    UserTypeEditController.isDeletingUserType = false;
                }
            );
        }

        UserTypeEditController.editUserType = editUserType;
        function editUserType(userType) {
            userType = DefaultVariableService.get(
                userType,
                UserTypeEditController.userType
            );

            UserTypeEditController.isEditingUserType = true;

            return UserTypeEditService.editUserType(userType).then(
                function(response) {
                    if (response) {
                        AlertService.addMessage('USER TYPE EDITED.');

                        UserTypeEditController.onEdit(response);
                    }

                    return response;
                }
            ).finally(
                function () {
                    UserTypeEditController.isEditingUserType = false;
                }
            );
        }

        UserTypeEditController.reset = reset;
        function reset() {
            UserTypeEditController.isDeletingUserType = false;

            UserTypeEditController.isEditingUserType = false;

            UserTypeEditController.isLoadingFeatures = false;

            UserTypeEditController.features = {};
        }

        UserTypeEditController.init = init;
        function init() {
            UserTypeEditController.reset();
        }

        LoginService.register(UserTypeEditController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicUserType').directive(
        'ionicUserTypeEdit',
        ionicUserTypeEdit
    );

    function ionicUserTypeEdit() {
        return {
            controller:   'UserTypeEditController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {
                onCancel: '=',
                onDelete: '=',
                onEdit:   '=',
                userType: '='
            },
            template:'<div><label class="item item-input item-floating-label"><span class="input-label">NAME</span> <input type="text" placeholder="NAME" data-ng-model="ctrl.userType.name"></label><div class="row"><button class="button button-full accept-button col-xl-6" data-ng-click="ctrl.editUserType()" data-ng-disabled="ctrl.isEditingUserType || ctrl.isDeletingUserType">{{ ctrl.isEditingUserType ? \'EDITING...\' : \'EDIT\' }}</button><button class="button button-full decline-button col-xl-6" data-ng-click="ctrl.cancel()" data-ng-disabled="ctrl.isEditingUserType || ctrl.isDeletingUserType">CANCEL</button></div><div><h3>FEATURES</h3><ionic-feature-list on-cancel="ctrl.cancel" user-type="ctrl.userType"></ionic-feature-list><h3>SETTINGS</h3><ionic-settings on-cancel="ctrl.cancel" options="ctrl.settingsOptions"></ionic-settings></div></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicUserType').controller(
        'UserTypeFilterController',
        UserTypeFilterController
    );
    
    UserTypeFilterController.$inject = [
        'DefaultVariableService',
        'LoginService',
        '$scope',
        'UserTypeListService'
    ];
    
    function UserTypeFilterController(
        DefaultVariableService,
        LoginService,
        $scope,
        UserTypeListService
    ) {
        var UserTypeFilterController = this;

        UserTypeFilterController.target = DefaultVariableService.getObject();

        $scope.$watch(
            'onSelect',
            function(onSelect) {
                UserTypeFilterController.onSelect = DefaultVariableService.getPromise(
                    onSelect
                );
            }
        );

        $scope.$watch(
            'target',
            function(target) {
                target = DefaultVariableService.getObject(
                    target
                );

                if (target.id !== 0 && target.id !== UserTypeFilterController.target.id) {
                    UserTypeFilterController.target = target;
                }
            }
        );

        UserTypeFilterController.clear = clear;
        function clear() {
            UserTypeFilterController.reset();
            UserTypeListService.reset();
        }
        
        UserTypeFilterController.loadUserTypes = loadUserTypes;
        function loadUserTypes(options) {
            UserTypeFilterController.isLoadingUserTypes = true;

            return UserTypeListService.getUserTypes(options).then(
                function(data) {
                    if (data) {
                        UserTypeFilterController.userTypes = DefaultVariableService.getArray(
                            data.user_types
                        );
                    }

                    return data;
                }
            ).finally(
                function() {
                    UserTypeFilterController.isLoadingUserTypes = false;
                }
            );
        }

        UserTypeFilterController.selectUserType = selectUserType;
        function selectUserType(userType) {
            UserTypeFilterController.target = userType;

            UserTypeFilterController.onSelect(userType);
        }

        UserTypeFilterController.reset = reset;
        function reset() {
            UserTypeFilterController.isLoadingUserTypes = true;

            UserTypeFilterController.userTypes = [];
        }

        UserTypeFilterController.init = init;
        function init() {
            UserTypeFilterController.reset();
            UserTypeFilterController.loadUserTypes();
        }

        LoginService.register(UserTypeFilterController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicUserType').directive(
        'ionicUserTypeFilter',
        ionicUserTypeFilter
    );

    function ionicUserTypeFilter() {
        return {
            controller:   'UserTypeFilterController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {
                onSelect: '=',
                target:   '='
            },
            template:'<div><ionic-filter on-filter="ctrl.loadUserTypes" on-select="ctrl.selectUserType" target="ctrl.target" title="display_name" toggle-message="SELECT A USER TYPE..."></ionic-filter></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicUserType').controller(
        'UserTypeManagementController',
        UserTypeManagementController
    );

    UserTypeManagementController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'LoginService',
        'RequestManagementService',
        'UserTypeManagementService'
    ];

    function UserTypeManagementController(
        AlertService,
        DefaultVariableService,
        LoginService,
        RequestManagementService,
        UserTypeManagementService
    ) {
        var UserTypeManagementController = this;

        UserTypeManagementController.createUserType = createUserType;
        function createUserType() {
            UserTypeManagementController.isCreatingUserType = true;

            return UserTypeManagementService.createUserType(
                UserTypeManagementController.newUserType
            ).then(
                function(response) {
                    if (response) {
                        AlertService.addMessage('USER TYPE CREATED.');

                        UserTypeManagementController.resetNewUserType();

                        UserTypeManagementController.loadUserTypes();
                    }

                    return response;
                }
            ).finally(
                function () {
                    UserTypeManagementController.isCreatingUserType = false;
                }
            );
        }

        UserTypeManagementController.loadUserTypes = loadUserTypes;
        function loadUserTypes(options) {
            UserTypeManagementController.isLoadingUserTypes = true;

            return UserTypeManagementService.getUserTypes(options).then(
                function(data) {
                    if (data) {
                        UserTypeManagementController.userTypes = DefaultVariableService.getArray(
                            data.user_types
                        );

                        UserTypeManagementController.count = DefaultVariableService.getInteger(
                            data.count,
                            0
                        );
                    }

                    return data;
                }
            ).finally(
                function() {
                    UserTypeManagementController.isLoadingUserTypes = false;
                }
            );
        }

        UserTypeManagementController.onUpdate = onUpdate;
        function onUpdate() {
            UserTypeManagementController.showCreator = false;

            UserTypeManagementController.resetUserType();

            UserTypeManagementController.loadUserTypes();
        }

        UserTypeManagementController.toggleCreator = toggleCreator;
        function toggleCreator() {
            UserTypeManagementController.showCreator = !UserTypeManagementController.showCreator;
        }

        UserTypeManagementController.toggleEditor = toggleEditor;
        function toggleEditor(userType) {
            userType = DefaultVariableService.getObject(userType);

            if (userType.id === 0) {
                UserTypeManagementController.resetUserType();
                UserTypeManagementController.resetNewUserType();
            } else {
                UserTypeManagementController.userType = userType;
            }
        }

        UserTypeManagementController.reset = reset;
        function reset() {
            UserTypeManagementController.columns = [
                {
                    position: 1,
                    title:    'ID',
                    key:      'id'
                },
                {
                    position: 2,
                    title:    'NAME',
                    key:      'name'
                }
            ];

            UserTypeManagementController.count = 0;

            UserTypeManagementController.isLoadingUserTypes = false;

            UserTypeManagementController.isCreatingUserType = false;

            UserTypeManagementController.resetNewUserType();

            UserTypeManagementController.showCreator = false;

            UserTypeManagementController.resetUserType();

            UserTypeManagementController.userTypes = {};
        }

        UserTypeManagementController.resetNewUserType = resetNewUserType;
        function resetNewUserType() {
            UserTypeManagementController.newUserType = DefaultVariableService.getObject();
        }

        UserTypeManagementController.resetUserType = resetUserType;
        function resetUserType() {
            UserTypeManagementController.userType = DefaultVariableService.getObject();

            UserTypeManagementController.settingsOptions = RequestManagementService.getRequest();
        }

        UserTypeManagementController.init = init;
        function init() {
            UserTypeManagementController.reset();
            UserTypeManagementController.loadUserTypes();
        }

        LoginService.register(UserTypeManagementController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicUserType').directive(
        'ionicUserTypeManagement',
        ionicUserTypeManagement
    );

    function ionicUserTypeManagement() {
        return {
            controller:   'UserTypeManagementController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<div data-ng-if="ctrl.userType.id === 0 && !ctrl.showCreator"><button class="button button-full accept-button" data-ng-click="ctrl.toggleCreator()">CREATE USER TYPE</button><ionic-data-table columns="ctrl.columns" objects="ctrl.userTypes" on-request="ctrl.loadUserTypes" on-select="ctrl.toggleEditor" total="ctrl.count"></ionic-data-table></div><ionic-user-type-edit data-ng-if="ctrl.userType.id !== 0" on-cancel="ctrl.toggleEditor" on-delete="ctrl.onUpdate" on-edit="ctrl.onUpdate" user-type="ctrl.userType"></ionic-user-type-edit><ionic-user-type-create data-ng-if="ctrl.showCreator" on-cancel="ctrl.toggleCreator" on-create="ctrl.onUpdate"></ionic-user-type-create>'
        };
    }
})();